.sub-menu-parent {
  position: relative;
}

.sub-menu {
  visibility: hidden; /* hides sub-menu */
  opacity: 0;
  background: transparent;
  position: absolute;
  top: 70%;
  left: -50%;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  padding-left: 0;
}

.sub-menu-parent:focus .sub-menu,
.sub-menu-parent:focus-within .sub-menu,
.sub-menu-parent:hover .sub-menu {
  visibility: visible; /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
}

.sub-menu li {
  margin-left: 0 !important;
  width: 100%;
  margin-bottom: 5px;
}

.main-logo {
  width: 25%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
}